import React, {useEffect} from 'react'
import ReservationsTable from './ReservationsTable'
import styles from './styles.module.css'
import Loading from './Loading'
import useReport from './useReport'

const Reservations = ({database, date, provider}) => {
  const {loading, report} = useReport(database, date.startDate, date.endDate, provider)
  return (
    <div className={styles.commonContainer}>
      <h1 className={styles.pageTitle}>Reservations</h1>
      {loading ? <Loading /> : <ReservationsTable data={report.reservations} />}
    </div>
  )
}

export default Reservations