import React, { useEffect, useState } from 'react'
import PropertiesTable from './PropertiesTable'
import styles from './styles.module.css'
import Loading from './Loading'
import useReport from './useReport'
import { doc, setDoc } from "firebase/firestore";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Properties = ({database, date, provider}) => {
  const {loading, report} = useReport(database, date.startDate, date.endDate, provider)
  const [updating, setUpdating] = useState(false)

  const updateProperty = async (propertyId, key, value) => {
    const ref = doc(database, 'properties', propertyId)
    try {
      await setDoc(ref, {[key]: parseInt(value)}, {merge: true})
      toast('Property Updated.', {
        position: 'bottom-right',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false
      })
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <div className={styles.commonContainer}>
      <ToastContainer />
      <h1 className={styles.pageTitle}>Manage Properties</h1>
      {loading ? <Loading /> : <PropertiesTable data={report.allProperties} updateProperty={updateProperty}/>}
    </div>
  )
}

export default Properties