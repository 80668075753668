import React, {useEffect} from 'react'
import styles from './styles.module.css'
import DateSelector from './DateSelector'
import Select from 'react-select'

const Header = ({date, provider, setProvider}) => {
  const { startDate, endDate, setStartDate, setEndDate } = date

  const onDateChange = (startDate, endDate) => {
    if (startDate) {
      setStartDate(startDate)
    } else {
      setEndDate(endDate)
    }
  }

  const providerOptions = [
    { value: 'ALL', label: 'All Providers'},
    { value: 'AIRBNB', label: 'AirBnB'},
    { value: 'HOMEAWAY', label: 'vrbo'}
  ]

  const handleChangeProvider = (selection) => {
    console.log(selection)
    setProvider(selection.value)
  }

  return (
    <div className={styles.header}>
      <div className={styles.providerSelectContainer}>
        <Select
          styles={{
            control: (base) => ({
              ...base,
              background: '#f0f3f9',
              border: 'none',
              borderRadius: '5px',
              boxShadow: 'none',
              outline:'none',
              width: '150px'
            }
            )
          }}
          defaultValue={providerOptions.find(opt => opt.value === provider)}
          options={providerOptions}
          onChange={handleChangeProvider}
        />
      </div>
      <span className={styles.headerSelectNote}>Dates:</span>
      <DateSelector 
        startDate={startDate}
        endDate={endDate}
        onDateChange={onDateChange}
      />
    </div>
  )
}

export default Header