import moment from 'moment';

const OK_STATUSES = ['ACCEPTED', 'POST_STAY', 'STAYING', 'BOOKING']
const OK_PROVIDERS = ['AIRBNB', 'HOMEAWAY', 'AIRBNB-OFFICIAL']

export default class Report {
  constructor(properties, rezzies, listings, startDate, endDate, provider) {
    const okProviders = provider === 'ALL' ? OK_PROVIDERS : [provider]
    this.properties = properties
    this.plainReservations = rezzies.filter(re => okProviders.includes(re.provider.toUpperCase())).filter(rez => OK_STATUSES.includes(rez.status.toUpperCase()))
    console.log(this.plainReservations)
    this.listings = listings
    this.reportLength = moment(endDate).diff(moment(startDate), 'days')
  }

  get performance() {
    return this.cachedPerformanceByProperty || this.calculatePerformanceByProperty()
  }

  get reservations() {
    return this.cachedReservationsWithProperties || this.reservationsWithProperties()
  }

  get overview() {
    return this.cachedPropertiesAggregation || this.propertiesAggregation()
  }

  get allProperties() {
    return this.cachedPropertiesWithRelations || this.propertiesWithRelations()
  }

  propertiesWithRelations() {
    const report = this.properties.map(property => {
      const reservations = this.reservationsByPropertyId(property.id)
      const listings = this.listingsByPropertyId(property.id)
      return {
        ...property,
        reservations: reservations,
        listings: listings
      }
    })
    this.cachedPropertiesWithRelations = report
    return report
  }

  reservationsWithProperties() {
    const report =  this.plainReservations.map(res => {
      const property = this.properties.find(prop => prop.id == res.propertyId)
      return {
        ...res,
        property,
      }
    })

    this.cachedReservationsWithProperties = report
    return report
  }

  reservationsByPropertyId(propertyId) {
    return this.plainReservations.filter(res => {
      const propertyMatch = res.propertyId == propertyId
      return propertyMatch
    })
  }

  listingsByPropertyId(propertyId) {
    return this.listings.filter(listing => {
      const propertyMatch = listing.propertyId == propertyId
      return propertyMatch
    })
  }

  propertiesAggregation() {
    let performance = this.performance

    let adults = 0
    let children = 0
    let infants = 0
    let totalReservations = 0
    let totalGuests = 0
    let nights = 0
    let cleaning = 0
    let bookedCapacity = 0
    let totalCapacity = 0
    let net = 0
    let gross = 0
    let payout = 0
    let occupancyRate


    performance.forEach(property => {
      adults += property.adults
      children += property.children
      infants += property.infants
      totalGuests += property.guests
      nights += property.nights
      payout += property.earnings.payout
      cleaning += property.fees.cleaning
      gross += property.earnings.gross
      net += property.earnings.net
      bookedCapacity += property.bookedCapacity
      totalCapacity += property.totalCapacity
      totalReservations += property.reservations
    })

    occupancyRate = nights / (performance.length * this.reportLength)

    const report = {
      guests: {
        total: adults + children + infants,
        adults,
        children,
        infants
      },
      totalReservations,
      nights,
      nightsAvailable: performance.length * this.reportLength,
      earnings: {
        net,
        gross,
        payout,
        average: net / this.reportLength,
      },
      totalCapacity,
      bookedCapacity,
      occupancyRate,
      totalReservations,
      fees: {
        cleaning
      }
    }

    this.cachedPropertiesAggregation = report
    console.log('propsAgg', report)

    return report
  }

  calculatePerformanceByProperty() {
    const report = this.properties.map(property => {
      const rezzies = this.reservationsByPropertyId(property.id)
      const rezIds = rezzies.map(res => res.uuid)
      const resAggregate = this.reservationsAggregation(rezIds)
      return {
        name: property.name,
        address: property.address.street,
        earnings: {
          gross: resAggregate.earnings.gross,
          net: resAggregate.earnings.net,
          payout: resAggregate.earnings.payout
        },
        fees: {
          cleaning: resAggregate.fees.cleaning,
          flat: resAggregate.fees.flat,
          percent: property.percentFee 
        },
        reservations: resAggregate.totalReservations,
        guests: resAggregate.guestCount,
        rooms: resAggregate.roomsCount,
        adults: resAggregate.adults,
        children: resAggregate.children,
        nights: resAggregate.nights,
        infants: resAggregate.infants,
        totalCapacity: resAggregate.totalCapacity,
        bookedCapacity: resAggregate.bookedCapacity,
        occupancyRate: resAggregate.nights / this.reportLength,
        earningPerDay: resAggregate.earnings.net / this.reportLength
      }
    })

    this.cachedPerformanceByProperty = report
    console.log('performance', report)

    return report
  }

  reservationsAggregation(reservationIds) {
    let gross = 0
    let guestCount = 0
    let roomsCount = 0
    let adults = 0
    let children = 0
    let infants = 0
    let totalCapacity = 0
    let bookedCapacity = 0
    let nights = 0
    let cleaning = 0
    let percent = 0
    let flat = 0
    let totalReservations = reservationIds.length
    reservationIds.forEach(uuid => {
      const reservation = this.plainReservations.find(res => res.uuid === uuid)
      const listing = this.listings.find(listing => listing.id == reservation.listing_id)
      const property = this.properties.find(property => property.id == reservation.propertyId)
      gross += reservation.subtotal.amount
      cleaning += property.cleaningFee || 0
      percent += property.percentFee ? Math.round(property.percentFee * reservation.subtotal.amount) / 100 : 0
      flat += property.flatFee || 0
      adults += reservation.occupancy.adults
      children += reservation.occupancy.children
      infants += reservation.occupancy.infants
      guestCount += (reservation.occupancy.adults + reservation.occupancy.children + reservation.occupancy.infants)
      bookedCapacity += (reservation.occupancy.adults + reservation.occupancy.children + reservation.occupancy.infants)
      nights += reservation.nights
      if (listing) {
        roomsCount += 1
        totalCapacity += listing.capacity.max
      }
    })
    const report = {
      totalCapacity,
      earnings: {
        payout: gross - flat - percent - cleaning,
        gross,
        net: gross - cleaning - flat - percent
      },
      fees: {
        flat,
        cleaning,
        percent
      },
      bookedCapacity,
      nights,
      adults,
      children,
      infants,
      roomsCount,
      guestCount,
      totalReservations
    }
    console.log('resagg', report)

    this.cachedReservationsAggregation = report

    return report
  }

}