import React, { useState } from 'react'
import styles from './styles.module.css'
import { useNavigate } from "react-router-dom";

import { getAuth, signInWithEmailAndPassword, EmailAuthProvider, signInWithPopup, signOut } from "firebase/auth";


const SignIn = ({setUser, app}) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const auth = getAuth(app);
  const navigate = useNavigate()

  const handleChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    if (name === 'email') {
      setEmail(value)
    } else {
      setPassword(value)
    }
  }
  const handleSignIn = async () => {
    try {
      const res = await signInWithEmailAndPassword(auth, email, password)
      setUser(res)
      navigate('/')
      localStorage.setItem('hospitality_user', res)
    } catch (e) {
      setError('Bad email/password.')
      console.error(e)
    }
  }

  return (
    <div className={styles.signInContainer}>
      <div className={styles.signIn}>
        <h1 className={styles.signInTitle}>CROSBY HOSPITALITY</h1>
          <div className={styles.signInBox}>
          <div>
            <h4 className={styles.signInCTA}>Sign in to your account.</h4>
            <div className={styles.signInInputContainer}>
              <input onChange={handleChange} type="text" name="email" placeholder="email" value={email}></input>
            </div>
            <div className={styles.signInInputContainer}>
              <input onChange={handleChange} type="password" name="password" placeholder="password" value={password}></input>
            </div>
          </div>
          <p className={`${styles.error}`}>{error}</p>
          <button className={styles.signInButton} onClick={handleSignIn}>LOG IN</button>
        </div>
      </div>
    </div>
  )
}

export default SignIn