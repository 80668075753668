import accounting from "accounting"

export const formatPercent = (num) => {
  const cented = num * 1000
  const rounded = Math.round(cented)
  const percented = rounded / 10
  if (isNaN(percented)) {
    return '-'
  }
  return `${percented}%`
}

export const formatMoney = (num) => {
  return accounting.formatMoney(num)
}