import React from 'react'
import DataTable from 'react-data-table-component';
import styles from './styles.module.css'
import { formatMoney } from './helpers';

const ReservationsTable = ({data}) => {
  const columns = [
    {
      name: 'Primary Guest',
      selector: row => {
        let guest = row._included[0].data
        return `${guest.first_name} ${guest.last_name}`
      } 
    },
    {
      name: 'Property',
      selector: row => row.property.name,
      sortable: true
    },
    {
      name: 'Check In',
      selector: row => row.check_in.split('T')[0],
      sortable: true
    },
    {
      name: 'Check Out',
      selector: row => row.check_out.split('T')[0],
      sortable: true
    },
    {
      name: 'Nights',
      selector: row => row.nights,
      width: '85px',
      sortable: true
    },
    {
      name: 'Subtotal',
      selector: row => formatMoney(row.subtotal.amount),
      width: '95px',
      sortable: true
    },
    {
      name: 'Payout',
      selector: row => formatMoney(row.subtotal.amount * (row.property.percentFee ? (1 - (row.property.percentFee / 100)) : 1)),
      sortable: true,
      width: '100px',
    },
    {
      name: 'Cleaning',
      selector: row => formatMoney(row.property.cleaningFee),
      width: '80px',
    },
    {
      name: '% Fee',
      selector: row => formatMoney(row.property.percentFee ? (row.property.percentFee / 100) * row.subtotal.amount : 0),
      width: '100px',
    },
    {
      name: 'Other Fees',
      selector: row => formatMoney(row.property.flatFee),
      width: '110px'
    },
    {
      name: 'Net Total',
      selector: row => formatMoney(row.subtotal.amount - row.property.cleaningFee - (row.property.percentFee ? (row.property.percentFee / 100) * row.subtotal.amount : 0))
    },
    {
      name: 'Guests',
      selector: row => row.occupancy.adults + row.occupancy.children + row.occupancy.infants,
      sortable: true
    },
    {
      name: 'Channel',
      selector: row => row.provider,
      sortable: true
    },
    {
      name: 'Code',
      selector: row => row.reservation_code,
      sortable: true
    },
    {
      name: 'Status',
      selector: row => row.status,
      sortable: true
    }
  ]

  return <div className={styles.tableContainer}>
    <DataTable
      columns={columns}
      data={data}
      className={styles.table}
      pagination
      defaultSortFieldId={4}
      defaultSortAsc={false}
    />
  </div>
}

export default ReservationsTable