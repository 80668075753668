import React, {useState, useEffect} from 'react'
import styles from './styles.module.css'

import {
  Link
} from "react-router-dom";

const SidebarItem = ({location}) => {
  return (
    <div>
      <Link className="link" to={location.to}>
        <div className={styles.sidebarItem}>
          <span>{location.title}</span>
        </div>
      </Link>
    </div>
  )
}

const Sidebar = ({open, config}) => {
  const locations = [
    {
      to: '/',
      title: 'Overview'
    },
    {
      to: '/performance',
      title: 'Performance'

    },
    {
      to: '/properties',
      title: 'Properties'
    },
    {
      to: '/reservations',
      title: 'Reservations'
    }
  ]
  console.log(config)
  return (
    <div className={styles.sidebar} style={{width: open ? '260px' : '0%'}}>
      <div className={styles.sidebarTitleContainer}>
        <h4 className={styles.sidebarTitle}>{config.organization}</h4>
      </div>
      <div className={styles.sidebarItemsContainer}>
        {locations.map(location => {
          return <SidebarItem location={location} />
        })}
      </div>
      <Link to="/sign_out">
        <div className={styles.signOutButton}>
          <span>Sign Out</span>
        </div>
      </Link>
    </div>
  )
}

export default Sidebar