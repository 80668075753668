import React from 'react'
import DataTable from 'react-data-table-component';
import styles from './styles.module.css'
import { formatPercent, formatMoney } from './helpers';

const TABLE_COLUMNS = [
  {
    name: 'Name',
    selector: row => row.name,
    width: '180px'
  },
  {
    name: 'Address',
    selector: row => row.address,
    width: '170px'
  },
  {
    name: 'Gross $',
    selector: row => formatMoney(row.earnings.gross),
    width: '110px'

  },
  {
    name: 'Payout $',
    selector: row => formatMoney(row.earnings.payout),
    width: '110px'

  },
  {
    name: 'Reservations',
    selector: row => row.reservations,
    width: '110px'
  },
  {
    name: 'Guests',
    selector: row => row.guests,
    width: '80px'
  },
  {
    name: 'Capacity',
    selector: row => row.totalCapacity,
    width: '110px'
  },
  {
    name: 'Booked',
    selector: row => row.bookedCapacity,
    width: '100px'

  },
  {
    name: 'Occ Rate',
    selector: row => formatPercent(row.occupancyRate),
    width: '110px'

  },
  {
    name: 'Avg $/Day',
    selector: row => formatMoney(row.earningPerDay),
    width: '150px'
  }
]


const PerformanceTable = ({data}) => {
  return <div className={styles.tableContainer}>
    <DataTable
      columns={TABLE_COLUMNS}
      data={data}
      className={styles.table}
      pagination
    />
  </div>
}

export default PerformanceTable