import React, {useEffect, useState} from 'react'
import moment from 'moment';
import Loading from './Loading'
import styles from './styles.module.css'
import useReport from './useReport'
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { formatPercent, formatMoney } from './helpers.js'
import accounting from 'accounting'

const Overview = ({database, date, provider}) => {
  let dollarUSLocale = Intl.NumberFormat('en-US');
  const {startDate, endDate, setStartDate, setEndDate} = date

  
  const {loading, report} = useReport(database, startDate, endDate, provider)

  const Carder = ({children}) => {
    return <div className={styles.card}>
      {children}
    </div>
  }

  const roundIt = (num) => {
    return Math.round(num * 1000) / 10
  }

  const Cards = () => {
    const {
      earnings,
      occupancyRate,
      guests,
      totalCapacity,
      bookedCapacity,
      totalReservations,
      fees,
      nights,
      nightsAvailable
    } = report.overview

    console.log(report.overview)

    return (
      <>
        <div className={styles.cardContainer}>
          <Row xs={1} md={2} lg={3} className="g-2">
            
          <Col>
            <Card
              border="light" 
              text={'dark'}
              style={{ height: '13rem' }}
              className="mb-2"
            >
              <Card.Header>Gross Earnings</Card.Header>
              <Card.Body>
                <Card.Text>
                  <span className={styles.cardData}>{formatMoney(earnings.gross)}</span>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col>

          <Card
              border="light" 
              text={'dark'}
              style={{ height: '13rem' }}
              className="mb-2"
            >
              <Card.Header>Payout</Card.Header>
              <Card.Body>
                <Card.Text>
                  <span className={styles.cardData}>{formatMoney(earnings.payout)}</span>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card
              border="light" 
              text={'dark'}
              style={{ height: '13rem' }}
              className="mb-2"
            >
              <Card.Header>Cleaning Fees</Card.Header>
              <Card.Body>
                <Card.Text>
                  <span className={styles.cardData}>{formatMoney(fees.cleaning)}</span>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card
            border="light" 
            text={'dark'}
            style={{ height: '13rem' }}
            className="mb-2"
          >
            <Card.Header>Avg. Daily Earnings</Card.Header>
            <Card.Body>
              <Card.Text>
              <span className={styles.cardData}>{formatMoney(earnings.average)}</span>
              </Card.Text>
            </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card
            border="light" 
            text={'dark'}
            style={{ height: '13rem' }}
            className="mb-2"
          >
            <Card.Header>Occupancy Rate</Card.Header>
            <Card.Body>
              <Card.Text>
                <span className={styles.cardData}>{formatPercent(occupancyRate)}</span>
                <span className={styles.cardSubData}>Nights Booked: {nights}</span>
                <span className={styles.cardSubData}>Nights Available: {nightsAvailable}</span>
              </Card.Text>
            </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card
            border="light" 
            text={'dark'}
            style={{ height: '13rem' }}
            className="mb-2"
          >
            <Card.Header>Total Guests</Card.Header>
            <Card.Body>
              <Card.Text>
                <span className={styles.cardData}>{guests.total}</span>
              </Card.Text>
            </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card
            border="light" 
            text={'dark'}
            style={{ height: '13rem' }}
            className="mb-2"
          >
            <Card.Header>Guest Breakdown</Card.Header>
            <Card.Body>
              <Card.Text>
                <span className={styles.cardData}>{guests.adults} Adults ({formatPercent(guests.adults / guests.total)})</span>
                <span className={styles.cardData}>{guests.children} Children ({formatPercent(guests.children / guests.total)})</span>
                <span className={styles.cardData}>{guests.infants} Infants ({formatPercent(guests.infants / guests.total)})</span>
              </Card.Text>
            </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card
            border="light" 
            text={'dark'}
            style={{ height: '13rem' }}
            className="mb-2"
          >
            <Card.Header>Total Reservations</Card.Header>
            <Card.Body>
              <Card.Text>
                <span className={styles.cardData}>{totalReservations}</span>
              </Card.Text>
            </Card.Body>
            </Card>
          </Col>
          </Row>

        </div>
      </>
    )
  }

  return (
    <div className={styles.commonContainer}>
      <h1 className={styles.pageTitle}>Overview</h1>
      {loading ? <Loading /> : <Cards />}
    </div>
  )
}

export default Overview