import React, {useState, useEffect} from 'react'
import { collection, query, where, getDocs } from "firebase/firestore";
import Loading from './Loading'
import DataTable from 'react-data-table-component';
import Overview from './Overview'
import Sidebar from './Sidebar'
import Header from './Header'
import SignOut from './SignOut'
import Performance from './Performance'
import Reservations from './Reservations'
import Properties from './Properties'
import styles from './styles.module.css'
import "react-datepicker/dist/react-datepicker.css";
import {
  BrowserRouter,
  Routes,
  Route,
  Link
} from "react-router-dom";
import moment from 'moment'

const Base = ({database, config}) => {

  const [calendars, setCalendars] = useState([])
  const [sidebarOpen, setSidebarOpen] = useState(true)
  const [startDate, setStartDate] = useState(moment().startOf('month'))
  const [endDate, setEndDate] = useState(moment().endOf('month'))
  const [provider, setProvider] = useState('ALL')


  const [date, setDate] = useState({
    startDate,
    endDate,
    setStartDate,
    setEndDate
  })

  useEffect(() => {
    setDate({
      startDate,
      endDate,
      setStartDate,
      setEndDate
    })
  }, [startDate, endDate])

  const path = window.location.pathname

  const getCalendars = async () => {
    const q = query(collection(database, "calendars"));
    const querySnapshot = await getDocs(q);
    let data = []
    querySnapshot.forEach((doc) => {
      data.push(doc.data())
    })
    setCalendars(data)
    return true
  }

  return (
    <div className={styles.app}>
      <Sidebar open={sidebarOpen} setSidebarOpen={setSidebarOpen} config={config} />
      <div className={styles.mainContainer} style={{width: sidebarOpen ? 'calc(100% - 260px)' : '100%'}}>
        <Header date={date} setProvider={setProvider} provider={provider} />
          <Routes>
            <Route path="/">
              <Route index element={<Overview date={date} database={database} provider={provider} />} />
              <Route path="performance" element={<Performance database={database} date={date} provider={provider} />} />
              <Route path="reservations" element={<Reservations database={database} date={date} provider={provider} />} />
              <Route path="properties" element={<Properties database={database} date={date} provider={provider} />} />
              <Route path="sign_out" element={<SignOut />} />
            </Route>
          </Routes>
      </div>
    </div>
  )
}

export default Base