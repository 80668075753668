import React, {useEffect} from 'react'
import { useNavigate } from "react-router-dom";

const SignOut = ({database, date}) => {
  const navigate = useNavigate()

  useEffect(() => {
    localStorage.removeItem('hospitality_user')
    window.location = '/'
  }, [])
}

export default SignOut