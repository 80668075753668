import React from 'react'
import DatePicker from "react-datepicker";
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css'
import styles from './styles.module.css'

const DateSelector = ({startDate, endDate, onDateChange}) => {

  return (
    <div className={styles.pickerContainer}>
      <div className={styles.pickerWrapper}>
        <DatePicker
          selected={moment(startDate).toDate()}
          onChange={date => onDateChange(date, null)}
          className={styles.picker}
        />
      </div>
      <div className={styles.pickerMiddle}>
        -
      </div>
      <div className={styles.pickerWrapper}>
        <DatePicker
          selected={moment(endDate).toDate()}
          onChange={date => onDateChange(null, date)}
          className={styles.picker}
        />
      </div>
    </div>
  )
}

export default DateSelector