import React, {useEffect} from 'react'
import PerformanceTable from './PerformanceTable'
import styles from './styles.module.css'
import Loading from './Loading'
import useReport from './useReport'

const Performance = ({database, date, provider}) => {
  const {loading, report} = useReport(database, date.startDate, date.endDate, provider)

  return (
    <div className={styles.commonContainer}>
      <h1 className={styles.pageTitle}>Performance by Property</h1>
      {loading ? <Loading /> : <PerformanceTable data={report.performance} />}
    </div>
  )
}

export default Performance