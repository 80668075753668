import React, {useState} from 'react'
import Base from './components/Base'
import { initializeApp, getApp } from "firebase/app";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getFirestore } from "firebase/firestore";
import SignIn from './components/SignIn'
import './components/styles.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  BrowserRouter,
  Routes,
  Route,
  Link
} from "react-router-dom";

const firebaseConfig = {
  apiKey: "AIzaSyC40aLX0UQvoO-EI6sPCNBHQlLNXSY7nZg",
  authDomain: "crosby-b2b1e.firebaseapp.com",
  projectId: "crosby-b2b1e",
  storageBucket: "crosby-b2b1e.appspot.com",
  messagingSenderId: "445724203670",
  appId: "1:445724203670:web:240bd5fcf0acf572ac80b7"
};

const app = initializeApp(firebaseConfig);
const functions = getFunctions(getApp());
const database = getFirestore(app);

connectFunctionsEmulator(functions, "localhost", 3333);

const App = () => {
  const [user, setUser] = useState(localStorage.getItem('hospitality_user') || null)

  return (
    <div className="App">
      <BrowserRouter>
        {user ? (
          <Base
            config={{
              organization: 'Crosby Hospitality',
              user: user
            }}
            firebase={app}
            user={user}
            database={database}
          />
        ) : (
          <SignIn setUser={setUser} app={app} user={user}/>
        )}
      </BrowserRouter>
    </div>
    
  )
}

export default App;
