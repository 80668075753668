import React from 'react'
import DataTable from 'react-data-table-component';
import styles from './styles.module.css'

const PropertiesTable = ({data, updateProperty}) => {
  let dollarUSLocale = Intl.NumberFormat('en-US');
  console.log(data)

  const normalizeProvider = {
    'homeaway': 'vrbo',
    'airbnb': 'AirBnB',
    'booking': 'AirBnB',
    'HOMEAWAY': 'vrbo'
  }
  const myColumns = [
    {
      name: 'Name',
      selector: row => row.name
    },
    {
      name: 'Listings',
      width: '500px',
      selector: row => {
        return <div>
          {row.listings.map(listing => {
            console.log(listing.provider)
            return <p>{normalizeProvider[listing.provider]} - {listing.name}</p>
          })}
        </div>
      }
    },
    {
      name: 'Cleaning Fee',
      selector: row => {
        return <div className={styles.tableInputContainer}>
          $<input
            onChange={({target}) => updateProperty(row.id, 'cleaningFee', target.value)}
            defaultValue={row.cleaningFee || 0}
            type="number"
            min="0"
            step="1"
          />
        </div>
      }
    },
    {
      name: 'Provider % Fee',
      selector: row => {
        return <div className={styles.tableInputContainer}>
          <input
            onChange={({target}) => updateProperty(row.id, 'percentFee', target.value)}
            defaultValue={row.percentFee || 0}
            type="number"
            min="0"
            step="1"
          />%
        </div>
      }
    },
    {
      name: 'Provider Flat Fee',
      selector: row => {
        return <div className={styles.tableInputContainer}>
          $<input
            onChange={({target}) => updateProperty(row.id, 'flatFee', target.value)}
            defaultValue={row.flatFee || 0}
            type="number"
            min="0"
            step="1"
          />
        </div>
      }
    },

  ]
  return <div className={styles.tableContainer}>
    <DataTable
      columns={myColumns}
      data={data}
      className={styles.table}
      pagination
    />
  </div>
}

export default PropertiesTable