import React, {useEffect, useState} from 'react'
import { collection, query, where, getDocs } from "firebase/firestore";
import moment from 'moment';
import Report from './ReportGenerator';

const useReport = (database, startDate, endDate, provider) => {
  const [report, setReport] = useState(null)
  const [properties, setProperties] = useState(null)
  const [reservations, setReservations] = useState(null)
  const [listings, setListings] = useState(null)
console.log(provider)
  useEffect(() => {
    getProperties()
    getReservations()
    getListings()
  }, [startDate, endDate, provider])

  useEffect(() => {
    getProperties()
    getReservations()
    getListings()
  }, [])

  useEffect(() => {
    if (listings && properties && reservations) {
      const report = new Report(properties, reservations, listings, startDate, endDate, provider)
      setReport(report)  
    }
  }, [listings, properties, reservations, provider])

  const getProperties = async () => {
    const q = query(collection(database, "properties"));
    const querySnapshot = await getDocs(q);
    let data = []
    querySnapshot.forEach((doc) => {
      data.push(doc.data())
    })
    console.log(data)

    setProperties(data)
    return true
  }

  const getReservations = async () => {
    const q = query(collection(database, "reservations"));
    const querySnapshot = await getDocs(q);
    let data = []
    querySnapshot.forEach((doc) => {
      const info = doc.data()
      if (moment(startDate) <= moment(info.check_out) && moment(endDate) >= moment(info.check_in)) {
        data.push(doc.data())
      }
    })
    console.log(data)
    setReservations(data)
    return true
  }

  const getListings = async () => {
    const q = query(collection(database, "listings"));
    const querySnapshot = await getDocs(q);
    let data = []
    querySnapshot.forEach((doc) => {
      data.push(doc.data())
    })
    console.log(data)

    setListings(data)
    return true
  }

  return {
    report,
    loading: !report
  }
}

export default useReport